import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Skeleton } from 'antd-mobile'
export default function Outside() {
  const [title,setTitle] = useState(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    document.title = '小意问答'
    if(pathname.includes('index')){
      setTitle('小意问答')
    }else if(pathname.includes('list')){
      setTitle('问题列表')
    }else if(pathname.includes('detail')){
      setTitle('问题答案')
    }else if(pathname.includes('login')){
      setTitle('登录')
    }
  },[pathname])
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      <NavBar 
        className='crm-header' 
        backArrow={!pathname.includes('index') && !pathname.includes('login')} 
        onBack={()=>navigate(-1)}
      >
        {title}
      </NavBar>
      <Outlet/>
    </Suspense>
  )
}