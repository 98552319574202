import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Skeleton } from 'antd-mobile'

export default function List() {
  const [title,setTitle] = useState('CRM')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
      if(pathname.includes('list')){
        setTitle('我的考试')
      }else if(pathname.includes('center')){
        setTitle('考试中心')
      }else if(pathname.includes('detail')){
        setTitle('正在考试')
      }else if(pathname.includes('paper')){
        setTitle('考卷详情')
      }
      
  },[pathname])
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      <NavBar className='crm-header' onBack={()=>navigate(-1)}>{title}</NavBar>
      <Outlet/>
    </Suspense>
  )
}
