// import React from 'react'

// export default function Home() {
//   return (
//     <div>
//       Home页面
//     </div>
//   )
// }


import React,{ useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { Button, NavBar, TabBar } from 'antd-mobile'
import {AppOutline,UserOutline} from 'antd-mobile-icons'
import './index.scss'

const tabs = [
  {
    key: 'index',
    title: '首页',
    icon: <AppOutline />,
  },
  {
    key: 'mine',
    title: '我的',
    icon: <UserOutline />,
  }
]
export default () => {
  const navigate = useNavigate()
  const [title,setTitle] = useState('首页')
  const setRouteActive = (value) => {
    navigate(value)
    let str = value === 'index' ? '首页' : '我的'
    setTitle(str)
  }
  return (
    <div className='app'>
      <NavBar back={null}  className='crm-header'>{title}</NavBar>
      <div className='body'>
        <Outlet/>
      </div>
      <div className='bottom'>
        <TabBar onChange={value => setRouteActive(value)}>
          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  )
}