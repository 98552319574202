import api from "../https/api"
const checkPhone = async (rule,value) => {
  
  if(!value){
    return Promise.resolve()
  }
  
  if(!/^1[3456789]\d{9}$/.test(value)){
    return Promise.reject('手机号码格式错误')
  }

  return Promise.resolve()
}
const checkMoney = (rule,value) => {

  if(!value){
    return Promise.resolve()
  }
  if(!/^[1-9][0-9.]{2,9}$/.test(value)){
    return Promise.reject('金额格式不正确')
  }
  return Promise.resolve()
}
const checkIdCard = (rule,value) => {
  if(!value){
    return Promise.resolve()
  }
  if(!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)){
    return Promise.reject('身份证号码格式错误')
  }
  return Promise.resolve()
}
const checkCode = (rule,value) => {
  if(!value){
    return Promise.resolve()
  }
  if(!/^[0-9]{6}$/.test(value)){
    return Promise.reject('验证码格式错误')
  }
  return Promise.resolve()
}

const checkCaptcha = async (rule,value) => {

  if(!value){
    return Promise.resolve()
  }
  if(!/^[0-9]{5}$/.test(value)){
    return Promise.reject('验证码格式错误')
  }

  const {data:{code,msg}} = await api.checkCaptcha({code:value})

  if(code === -1){
    return Promise.reject(msg)
  }
  return Promise.resolve()
}
export {
  checkPhone,
  checkMoney,
  checkIdCard,
  checkCode,
  checkCaptcha
}