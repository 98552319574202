import React from 'react'
import './index.scss'
export default function Contract() {
  return (
    <div className='contract'>
      <div className='header'>代购订单</div>
      <div className='desc'>
      经双方友好沟通协商，就甲方（买方）委托乙方（代购方）进行车辆代购相关事宜达成一致，买方选择签订本代购订单。
      </div>
      <table className='table'>
        <thead>
          <tr className='title'>
            <td colSpan={4}>买方信息</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>买方名称</td>
            <td>蔡徐坤</td>
            <td>身份证号码</td>
            <td>511133197301240418</td>
          </tr>
          <tr>
            <td>联系电话</td>
            <td>15342571003</td>
            <td>联系地址</td>
            <td>四川省马边彝族自治县劳动镇金星村1组11号</td>
          </tr>
        </tbody>
        <thead>
          <tr className='title'>
            <td colSpan={4}>代购车辆信息</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>订购车型</td>
            <td colSpan={4}>小米su7</td>
          </tr>
          <tr>
            <td>颜色</td>
            <td>海湾蓝</td>
            <td>内饰色</td>
            <td>橄榄绿</td>
          </tr>
          <tr>
            <td>厂方指导价</td>
            <td>人民币<span className='price'>360800</span>元</td>
            <td>车辆销售价</td>
            <td>人民币<span className='price'>356800</span>元</td>
          </tr>
          <tr>
            <td>首付款金额</td>
            <td>人民币<span className='price'>56800</span>元</td>
            <td>分期贷款金额</td>
            <td>人民币<span className='price'>300000</span>元</td>
          </tr>
          <tr>
            <td>定金</td>
            <td>人民币<span className='price'>5000</span>元</td>
            <td>预计交车日期</td>
            <td>2024年10月28日</td>
          </tr>
        </tbody>
      </table>

      <div className='content'>
        <div className='title'>以下条款请客户务必仔细阅读</div>
        <div>1、交车具体日期以经销商向甲方发出的提车通知（一般以电话方式）中指明日期为准。甲方以信用卡分期形式支付款项的，甲方授权并同意银行将分期款项直接发放至乙方指定账户。甲方承诺分期款项仅限用于购买上述车辆，不得改变分期用途。甲方需按照乙方或银行要求，及时提供分期相关材料，并承诺所有材料均真实、合法、有效。</div>
        <div>2、甲方承诺，如通过信用卡分期形式支付，甲方不得在其他银行、金融机构、担保机构等再次办理车辆贷款。</div>
        <div>3、甲方承诺，该笔分期放款后2年（24期）内不得提前结清，如甲方不满2年提前结清的，需向乙方支付分期本金12%的违约金。</div>
        <div>4、车辆交付时，因厂商原因或国家政策调整致车价上调或优惠幅度减少，则车辆售价作相应上调。</div>
        <div>5、车辆配置及技术参数以厂商出厂配置及技术参数为准，如因厂商调整车辆配置或技术参数，导致交付时车辆配置与订车时不符，不视为乙方违约。如厂商实质性更改了车辆配置或技术参数，或停止该车型的生产，那么任何一方可书面通知另一方解除本订单。甲方已支付给乙方的定金由乙方无息全额返还。甲方无需为此承担违约责任。</div>
        <div>6、乙方承诺所代购车辆享有原厂质量保证，所有质量问题按厂方质保条款处理。甲方对所购车辆的配置、型号、外观、内装、颜色等有异议的应在验车当场提出异议，否则视为乙方代购的车辆符合甲方要求。</div>
        <div>7、乙方承诺所代购车辆享有原厂质量保证，所有质量问题按厂方质保条款处理。甲方对所购车辆的配置、型号、外观、内装、颜色等有异议的应在验车当场提出异议，否则视为乙方代购的车辆符合甲方要求。</div>
        <div>8、如因甲方违约（包括但不限于拒绝提车、未履行上述承诺等）或甲方个人原因（包括但不限于资信不足无法通过贷款审批、个人变更购车计划等）致使本订单无法履行或使乙方遭受损失的，乙方有权解除本订单，并要求甲方赔偿由此给乙方造成的全部损失。</div>
        <div>9、如出现不可抗力因素，双方在不可抗力影响范围及其持续期间内暂停履行本订单，同时双方应尽快协商解决方案。不可抗力因素消失后，如双方无其他约定的，应当继续履行本订单。</div>
        <div>10、本订单自甲方签字、乙方加盖公章或合同专用章后生效。</div>
        <div>11、在订单履行过程中发生争议，可友好协商解决，协商不成，任何一方可向乙方所在地人民法院提起诉讼。</div>
        <div className='title'>甲方确认：就以上条款任何不解之处已从乙方处获得充分的说明和解释，甲方已完全了解并全部接受上述条款内容。</div>
      </div>
      <div className='sign'>
        <div className='sign-name'>
          <span>甲方（签名）：蔡徐坤</span>
          <span>乙方（盖章）：</span>
        </div>
        <div className='sign-date'>签订日期：2024年10月29日</div>
      </div>
    </div>
  )
}
