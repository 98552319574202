// import React,{ useState } from 'react'
// import { Navigate, Outlet, useNavigate } from 'react-router-dom'
// import { Button, NavBar, TabBar } from 'antd-mobile'
// import {AppOutline,UserOutline} from 'antd-mobile-icons'
// import './index.scss'

// const tabs = [
//   {
//     key: 'index',
//     title: '首页',
//     icon: <AppOutline />,
//   },
//   {
//     key: 'mine',
//     title: '我的',
//     icon: <UserOutline />,
//   }
// ]
// export default () => {
//   const navigate = useNavigate()
//   const [title,setTitle] = useState('首页')
//   const setRouteActive = (value) => {
//     navigate(value)
//     let str = value === 'index' ? '首页' : '我的'
//     setTitle(str)
//   }
//   return (
//     <div className='app'>
//       <NavBar back={null}  className='crm-header'>{title}</NavBar>
//       <div className='body'>
//         <Outlet/>
//       </div>
//       <div className='bottom'>
//         <TabBar onChange={value => setRouteActive(value)}>
//           {tabs.map(item => (
//             <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
//           ))}
//         </TabBar>
//       </div>
//     </div>
//   )
// }
import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Skeleton } from 'antd-mobile'

export default function Yishubao() {
  const [title,setTitle] = useState('意数宝')
  const [show,setShow] = useState(true)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
      setShow(true)
      if(pathname.includes('list')){
        setTitle('团队数据')
      }else if(pathname.includes('appeal')){
        setTitle('反馈数据')
      }else if(pathname.includes('exchange')){
        setTitle('我要提现')
      }else if(pathname.includes('index')){
        setTitle('首页')
        setShow(false)
      }else if(pathname.includes('mine')){
        setTitle('我的')
        setShow(false)
      }
  },[pathname])
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      {
        show ? (
          <NavBar className='crm-header' onBack={()=>navigate(-1)}>{title}</NavBar>
        ):null
      }
      
      <Outlet/>
    </Suspense>
  )
}

