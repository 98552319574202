import axios from "axios"
import { BASE_URL } from '../utils/util'
axios.defaults.baseURL = BASE_URL
axios.interceptors.request.use((config) => {
  //添加请求头
  config.headers = {...config.headers,'Content-Type':'multipart/form-data'}
  return config
}) 

//响应处理
/* axios.interceptors.response.use(config => {
 return config
}) */
const http = {
  post:'',
  get:'',
  put:'',
  del:''
}

http.post = function(api,data){
  return new Promise((resolve, reject) => {
    axios.post(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}

http.get = function(api,data){
  return new Promise((resolve, reject) => {
    axios.get(api,{params:data}).then(renponse => {
      resolve(renponse)
    })
  })
}

http.put = function(api,data){
  return new Promise((resolve, reject) => {
    axios.put(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}

http.delete = function(api,data){
  return new Promise((resolve, reject) => {
    axios.delete(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}
http.patch = function(api,data){
  return new Promise((resolve, reject) => {
    axios.patch(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}
export default http