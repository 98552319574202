import http from "./http";
const api =  {
  userLogin(param){
    return http.post('/api/crm/login',param)
  },
  userAutoLogin(param){
    return http.post('/api/crm/autoLogin',param)
  },
  getUserCorp(){
    return http.get('/api/crm/corp')
  },
  getUserinfo(id){
    return http.get('/api/crm/userinfo',{id})
  },
  getSignList(){
    return http.get('/api/crm/sign')
  },
  getCityList(param){
    return http.get('/api/crm/city',param)
  },
  getChannelList(param){
    return http.post('/api/crm/channel',param)
  },
  getSourceList(param){
    return http.post('/api/crm/source',param)
  },
  getBankList(param){
    return http.post('/api/crm/bank',param)
  },
  getBranchAndProduct(param){
    return http.post('/api/crm/product',param)
  },
  addClientInfo(param){
    return http.post('/api/crm/addClient',param)
  },
  getClientList(param){
    return http.post('/api/crm/clientList',param)
  },
  getClientDetail(param){
    return http.get('/api/crm/detail',param)
  },
  delClientList(param){
    return http.post('/api/crm/delClient',param)
  },
  getMoneyList(param){
    return http.post('/api/crm/moneyList',param)
  },
  getMoneyConfirm(param){
    return http.post('/api/crm/moneyConfirm',param)
  },
  addMoneyInfo(param){
    return http.post('/api/crm/addMoney',param)
  },
  delMoneyInfo(param){
    return http.post('/api/crm/delMoney',param)
  },
  addMoneyProof(param){
    return http.post('/api/crm/proof',param)
  },
  getMoneyProof(param){
    return http.get('/api/crm/getProof',param)
  },
  updateMoneyProof(param){
    return http.post('/api/crm/updateProof',param)
  },
  deleteMoneyProof(param){
    return http.post('/api/crm/delProof',param)
  },
  addClientAppeal(param){
    return http.post('/api/crm/addAppeal',param)
  },
  getClientAppeal(param){
    return http.get('/api/crm/appeal',param)
  },
  getPendingList(param){
    return http.post('/api/crm/pending',param)
  },
  getPendingDetail(param){
    return http.get('/api/crm/pendingDetail',param)
  },
  getProofRejectList(param){
    return http.get('/api/crm/proofReject',param)
  },
  getStatisticData(param){
    return http.get('/api/crm/statistic',param)
  },
  getStatisticUserlist(param){
    return http.get('/api/crm/statUserlist',param)
  },
  //房贷业务
  getMortgageUser(param){
    return http.post('/api/crm/mortgageUser',param)
  },
  addHouseClientInfo(param){
    return http.post('/api/crm/addHouseClient',param)
  },
  addHouseClientImage(param){
    return http.post('/api/crm/addHouseImg',param)
  },
  getHouseClientList(param){
    return http.post('/api/crm/getHouseList',param)
  },
  getHouseClientDetail(param){
    return http.get('/api/crm/getHouseDetail',param)
  },
  getHouseClientImage(param){
    return http.get('/api/crm/getHouseImg',param)
  },
  delHouseClient(param){
    return http.post('/api/crm/delHouseClient',param)
  },
  //活动
  addActivityData(param){
    return http.post('/api/crm/activityAdd',param)
  },
  //意数宝
  getYsbQrcode(param){
    return http.post('/api/crm/ysbQrcode',param)
  },
  getYsbDataList(param){
    return http.post('/api/crm/ysbUser',param)
  },
  getYsbUserinvite(param){
    return http.post('/api/crm/ysbInvite',param)
  },
  getYsbOutsideClient(param){
    return http.post('/api/crm/ysbOutsideClient',param)
  },
  getYsbClient(param){
    return http.post('/api/crm/ysbClient',param)
  },
  //客服协助
  getServiceDataList(param){
    return http.get('/api/crm/service',param)
  },
  serviceTransfer(param){
    return http.post('/api/crm/serviceTransfer',param)
  },
  //团队
  getTeamData(param){
    return http.get('/api/crm/teamData',param)
  },
  getTeamAppeal(param){
    return http.get('/api/crm/teamAppeal',param)
  },
  getTeamHouse(param){
    return http.get('/api/crm/houseTeam',param)
  },
  getTeamYsb(param){
    return http.post('/api/crm/ysbTeam',param)
  },
  auditAppeal(param){
    return http.post('/api/crm/auditAppeal',param)
  },
  // 签约
  getTaskInfo(param){
    return http.post('/api/crm/taskList',param)
  },
  updateClict(param){
    return http.post('/api/crm/updateClient',param)
  },
  updateClientStatus(param){
    return http.post('/api/crm/updateClientStatus',param)
  },
  rejectUpdate(param){
    return http.post('/api/crm/updateClientReject',param)
  },
  //考试
  getPaperList(param){
    return http.get('/api/crm/paperList',param)
  },
  getPaperDetail(param){
    return http.get('/api/crm/paperDetail',param)
  },
  addPaperAnswer(param){
    return http.post('/api/crm/paperAnswer',param)
  },
  getAnswerList(param){
    return http.get('/api/crm/answerList',param)
  },
  getAnswerDetail(param){
    return http.get('/api/crm/answerDetail',param)
  },

  //渠道进件
  getOutsideUser(param){
    return http.get('/api/crm/getOutsideUser',param)
  },
  getOutsideUserTeam(param){
    return http.get('/api/crm/getOutsideUserTeam',param)
  },
  addOutsideUser(param){
    return http.post('/api/crm/outsideRegister',param)
  },
  outsideUserLogin(param){
    return http.post('/api/crm/outsideLogin',param)
  },
  addOutsideChennelClient(param){
    return http.post('/api/crm/outsideChannelAdd',param)
  },
  getOutsideProduct(param){
    return http.get('/api/crm/outsideProduct',param)
  },
  getOutsideBank(param){
    return http.get('/api/crm/outsideBank',param)
  },
  getOutsideBranchBank(param){
    return http.get('/api/crm/outsideBranchBank',param)
  },
  getOutsideClientList(param){
    return http.get('/api/crm/outsideClientList',param)
  },
  getOutsideClientDetail(param){
    return http.get('/api/crm/outsideClientDetail',param)
  },
  updateOutsideClientStatus(param){
    return http.post('/api/crm/updateOutsideClient',param)
  },
  addOutsideChannel(param){
    return http.post('/api/crm/outsideAddChannel',param)
  },
  deleteOutsideChannel(param){
    return http.get('/api/crm/outsideDelChannel',param)
  },
  outsideChannelList(param){
    return http.get('/api/crm/outsideChannelList',param)
  },
  //小意问答
  getFaqType(){
    return http.get('/api/crm/faqType')
  },
  getFaqCategory(param){
    return http.get('/api/crm/faqCategory',param)
  },
  getFaqList(param){
    return http.post('/api/crm/faqList',param)
  },
  getFaqDetail(param){
    return http.post('/api/crm/faqDetail',param)
  },
  //渠道建档(乾太健钱包)
  initWxWorkConfig(){
    return http.get('/api/chattergee/initWxWorkConfig')
  },
  //公共部分
  getStatusList(){
    return http.get('/common/status')
  },
  getOutsideStatus(){
    return http.get('/common/outsideStatus')
  },
  getHouseStatus(){
    return http.get('/common/houseStatus')
  },
  getBankTree(param){
    return http.get('/common/bankTree',param)
  },
  getChannelTree(param){
    return http.get('/common/channelTree',param)
  },
  getSignUser(param){
    return http.get('/common/signUser',param)
  },
  uploadImage(param){
    return http.post('/common/image',param)
  },
  deleteImage(param){
    return http.post('/common/delimg',param)
  },
  getPhoneCode(param){
    return http.get('/common/code',param)
  },
  checkCaptcha(param){
    return http.get('/common/checkCaptcha',param)
  },
  addWorkList(param){
    return http.post('/common/wxwork/addWorkList',param)
  },
  getWorkList(param){
    return http.get('/common/wxwork/workList',param)
  }

}

export default api