import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form,Button,Input, Toast,NavBar,Space,Picker, ErrorBlock } from 'antd-mobile'
import { checkPhone,checkCode } from '../../utils/validator'
import { MINIPROGRAM_URL, userinfo} from '../../utils/util'
import BtnCountdown from '../../components/BtnCountdown'
import api from '../../https/api'

export default function Login() {
  const {search} = useLocation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading,setLoading] = useState(false)
  const [corp,setCorp] = useState([])
  const [user] = useState(userinfo()) 
  const [wxwork,setWxwork] = useState({})
  const [isLogin,setIsLogin] = useState(false)
  useEffect(()=>{
    if(user){
      return navigate(`/index`,{state:{userid:user.id}})
    }
    const fetchData = async () => {
      if(!search.includes('wxworkid')){
        if(search.includes('corp')){
          let corp = search.split('=')[1]
          return window.location.href = MINIPROGRAM_URL + '/api/wechat/wxworkLogin?corp='+corp
        }
      }
      if(search.includes('wxworkid') && search.includes('corp')){
        autoLogin()
      }else{
        setIsLogin(true)
        const {data:{data}} = await api.getUserCorp()
        setCorp(data)
      }
    }
    fetchData()
  },[])

  const autoLogin = async () => {
    let arr = search.substring(1).split('&')
    let param = {}
    arr.forEach(item => {
      param[item.split('=')[0]] = item.split('=')[1]
    })
    setWxwork(param)
    const {data:{data,code}} = await api.userAutoLogin(param)
    
    if(code === 0){
      navigate(`/index`,{state:{userid:data.id}})
    }else{
      const {data:{data}} = await api.getUserCorp()
      setCorp(data)
      setIsLogin(true)
    }
  }

  const onFinish = async (values)=>{

    /* if(!wxwork.wxworkid){
      return Toast.show('未获取到企业微信授权信息，请重新进入应用')
    } */ 
    const phoneCode = JSON.parse(localStorage.getItem('phoneCode'))

    if(!phoneCode){
      return Toast.show('验证码输入错误')
    }
    if(values.phone !== phoneCode.phone || Number(values.code) !== phoneCode.code){
      return Toast.show('验证码输入错误')
    }

    setLoading(true)
    values.wxworkid = wxwork.wxworkid ? wxwork.wxworkid : null
    
    const {data:{code,data,msg}} = await api.userLogin(values)
    Toast.show(msg)
    if(code === 0){
      localStorage.removeItem('phoneCode')
      setTimeout(()=>{
        navigate(`/index`,{state:{userid:data.id}})
      },2000)
    }else{
      setLoading(false)
    }  
  }
  return (
    <div className='register'>
      <NavBar className='crm-header' backArrow={false}>登录</NavBar>
      {
        isLogin ? (
          <Form
            layout='horizontal'
            form={form}
            onFinish={onFinish}
            footer={
              <Button block type='submit' color='primary' size='large' loading={loading} loadingText="账号登录中">
                登录
              </Button>
            }
          >
            <Form.Item
              name='corp_id'
              label='所属公司'
              rules={[{ required: true, message: '公司必选' }]}
            >
              <Picker
                columns={[corp]}
                onConfirm={val => form.setFieldValue('corp_id',val)}
              >
                {(items,{open})=>{
                  return (
                    <Space onClick={open} align='center'>
                      {
                        items.every(item => item === null) ? (<span style={{color:'#ccc'}}>请选择</span>) : items.map(item => item.label)
                      }  
                    </Space>
                  )
                }}
              </Picker>
            </Form.Item>
            <Form.Item
              name='phone'
              label='手机号码'
              rules={[{ required: true, message: '手机号码必填' },{validator:checkPhone}]}
            >
              <Input  placeholder='手机号码' autoComplete='off' maxLength={11}/>
            </Form.Item>
            <Form.Subscribe to={['phone']}>
              {({phone})=>(
                <Form.Item
                  name='code'
                  label='短信验证码'
                  rules={[{ required: true, message: '验证码必填' },{validator:checkCode}]}
                  extra={<BtnCountdown phone={phone}/>}
                >
                  <Input  placeholder='验证码' autoComplete='off' maxLength={6}/>
                </Form.Item>
              )}
            </Form.Subscribe>
          </Form>
        ) : (
          <ErrorBlock 
            status='busy'
            title="企业微信授权中"
            description="请耐心等待..."
          />
        )
      }
    </div>
  )
}
