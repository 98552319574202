import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Skeleton } from 'antd-mobile'

export default function Sign() {
  const [title,setTitle] = useState('CRM')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
      if(pathname.includes('list')){
        pathname.slice(-1) === '1' ? setTitle('任务中心') : setTitle('我的任务')  
      }else if(pathname.includes('update')){
        setTitle('进件详情')
      }else if(pathname.includes('reject')){
        setTitle('驳回审批')
      }else if(pathname.includes('house')){
        setTitle('房贷对接')
      }
  },[pathname])
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      <NavBar className='crm-header' onBack={()=>navigate(-1)}>{title}</NavBar>
      <Outlet/>
    </Suspense>


  )
}
