import { useRoutes } from 'react-router-dom'
import routes from './routes';
function App() {
  return (
    <div className="App">
      {useRoutes(routes)}
    </div>
  )
}
/* const ua = navigator.userAgent.toLowerCase()
function App() {
  const crmRoutes = useRoutes(routes)
  const errMsg = <h2>请在企业微信内打开应用</h2>
  return (
    <div className="App">
      {
        ua.includes('wxwork') ? crmRoutes : errMsg 
      }
    </div>
  )
} */
export default App;
