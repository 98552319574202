import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useNavigate,useLocation } from 'react-router-dom'
import { NavBar, Skeleton } from 'antd-mobile'
export default function Business() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [title,setTitle] = useState('CRM') 
  useEffect(()=>{
    if (pathname.includes('/list')) {
      setTitle('我的进件')
    } else if(pathname.includes('add')){
      setTitle('新增进件')
    }else if(pathname.includes('/detail')){
      setTitle('进件详情')
    }else if(pathname.includes('money')){
      setTitle('放款信息')
    }else if(pathname.includes('upload')){
      setTitle('上传凭证')
    }else if(pathname.includes('edit')){
      setTitle('重新上传')
    }else if(pathname.includes('reject')){
      setTitle('驳回进件')
    }else if(pathname.includes('appeal')){
      setTitle('驳回进件')
    }else if(pathname.includes('pending')){
      setTitle('渠道进件')
    }else if(pathname.includes('service')){
      setTitle('客服协助')
    }else if(pathname.includes('houseUpload')){
      setTitle('上传房贷材料')
    }else if(pathname.includes('houseDetail')){
      setTitle('房贷详情')
    }else if(pathname.includes('houseAdd')){
      setTitle('新增房贷客户')
    }else if(pathname.includes('house')){
      setTitle('房贷进件数据')
    }else if(pathname.includes('ysb')){
      setTitle('意数宝')
    }else if(pathname.includes('calculator')){
      setTitle('贷款计算器')
    }else if(pathname.includes('stat')){
      setTitle('业务统计')
    }
  },[pathname])
  return (
    <Suspense 
      fallback={<>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>}
      >
      <NavBar className='crm-header' onBack={()=>navigate(-1)}>{title}</NavBar>
      <Outlet/>
    </Suspense>
  )
}
